export enum Query {
  DetectAddress = 'detect-address',
  Transactions = 'transactions',
  NFTDetail = 'nft-detail',
  NFTList = 'nft-list',
  Transfer = 'transfer',
  Explore = 'explore',
  Tags = 'tags',
  Profile = 'profile',
  Hotest = 'hotest',
  Collections = 'collections',
  Collection = 'collection',
  CollectionDetail = 'collection-detail',
  Issuers = 'Issuers',
  Notifications = 'Notifications',
  RedeemList = 'RedeemList',
  MyRedeemList = 'MyRedeemList',
  RedeemDetail = 'RedeemDetail',
  RedeemPrize = 'RedeemPrize',
  SendRedeem = 'SendRedeem',
  DetectClaim = 'DetectClaim',
  Claim = 'Claim',
  FollowedIssuers = 'FollowedIssuers',
  NftHolderList = 'nft-holder-list',
}
